body {
    margin: 0;
    padding: 0;
}

#candiv {
    /* border: solid 1px #000000; */
    width: 256px;
    margin: auto;
}

.dispWrap {
    margin: auto;
    width: 96px;
    height: 96px;
}

.dispImg {    
    width: 96px;
    height: 96px;
    margin-top: 85px;
    border: solid 2px #006600;
}

.square_on {
    background-image: url(mokume.png);
    /* width: 40px;     */
    /* height: 40px; */
    width: 32px;
    height: 32px;
    font-size: 16px;
    padding: 0;
    margin: 0;
    font-family: "Sawarabi Mincho";
    font-weight: bold;
    color: 'black';
}

.square_off {
    background-image: url(hagane.png);
    /* width: 40px;     */
    /* height: 40px; */
    width: 32px;
    height: 32px;
    font-size: 16px;
    padding: 0;
    margin: 0;
    font-family: "Sawarabi Mincho";
}


.myBtn {
    background-image: url(mokume2.png);
    width: 150px;    
    height: 30px;
    font-size: 15px;
    font-family: "Sawarabi Mincho";
}

.btn_wrapper {
    margin: auto;
    margin-top: 30px;
    margin-bottom: 30px;
    width: 150px;    
}
    
.board_wrapper {
    /* width: 440px; */
    width: 352px;
    padding : 0;
    margin : auto;
}

.info_wrapper {
    text-align: center;
    background-color: #ffffff;
    padding-top: 5px;
}

.predict_info_wrapper {
    text-align: center;
    background-color: #ffffff;
    font-size: 16px;
    margin: 0;
    padding: 0;
}

.predict_elem {
    margin : 0;
    padding: 0;
    padding-bottom: 5px;
}

.score_info_elem {
    text-align: center;
    margin: 0;
    padding: 0;
    padding-top: 10px;
}


.predictResult {
    color: orangered;
    font-size: 20px;
}

.score_wrapper {
    text-align: center;
    margin: auto;
    margin-top: 10px;
    width: 300px;
    background-color: #ffffff;
    padding: 10px;
}

.slider_wrapper {
    width: 300px;
    margin : auto;
    margin-bottom : 10px;
    background-color: #ffffff;
    padding: 10px;
}

#imgWrapper {
    margin: auto;
    padding : 0;
}

.sns_wrapper {
    width: 310px;
    margin : auto;
    margin-top : 20px;
    padding: 5px;
    background-image: url(mokume2.png);
}

/* 黒板風1 */
.kokuban-s1 {
    position: relative;
    margin: 2em auto;
    /* padding: 1em; */
    width: 60px; /* ボックス幅 */
    background: #202020; /* ボックス背景色 */
    color: #fff; /* 文章色 */
    border: 5px solid #b2771f; /* 枠線 */
    border-radius: 3px; /* 角の丸み */
    box-shadow: 0 0 5px #333;
    text-align:center;
}

/* 看板 */
.kanban_wrapper {
    position: relative;
    margin: 2em auto;
    /* padding: 1em; */
    width: 300px; /* ボックス幅 */
    background: #006633; /* ボックス背景色 */
    color: #fff; /* 文章色 */
    border: 5px solid #b2771f; /* 枠線 */
    border-radius: 3px; /* 角の丸み */
    box-shadow: 0 0 5px #333;
    text-align:center;
}

